:root {
  --grey2: #afb4b6;
  --grey3: #dfe1e1;
  --grey4: #fafafa;

  --home: #00dca3;
  --office: #0093c3;
  --else: #ffd166;
  --ooc: #dfe1e1;
  --off: #dfe1e1;
  --null: #ff3767;

  --home-200: #00dca34d;
  --office-200: #0093c34d;
  --else-200: #ffd1664d;
  --ooc-200: #dfe1e14d;
  --off-200: #dfe1e14d;
  --null-200: #ff37674d;
}

.cuse {
  background: currentColor;
  border: none;
  height: 40px;
  display: flex;
  align-items: center;
  margin: 3px 0;
}

.cuse--home {
  color: var(--home);
}
.cuse--office {
  color: var(--office);
}
.cuse--else {
  color: var(--else);
}
.cuse--ooc {
  color: var(--ooc);
}
.cuse--off {
  color: var(--off);
}
.cuse--null {
  color: var(--null);
  background: none;
}

.cuse--not-active.cuse--home {
  color: var(--home-200);
}
.cuse--not-active.cuse--office {
  color: var(--office-200);
}
.cuse--not-active.cuse--else {
  color: var(--else-200);
}
.cuse--not-active.cuse--ooc {
  color: var(--ooc-200);
}
.cuse--not-active.cuse--off {
  color: var(--off-200);
}
.cuse--not-active.cuse--null {
  color: var(--null-200);
  background: none;
}

.cuse--first {
  border-radius: 50px 0 0 50px;
  border-right: none;
  margin: 3px 0 3px 3px;
}
.cuse--last {
  border-radius: 0 50px 50px 0;
  border-left: none;
  margin: 3px 3px 3px 0;
}
.cuse--solo {
  border-radius: 50px;
  border-left: none;
  border-right: none;
  margin: 3px;
}

.cuse .null-slot {
  display: block;
}

.cuse--selected .slot-content {
  visibility: hidden;
  opacity: 0;
  transition: none;
}

.cuse--selected {
  background: var(--grey4);
  border: solid 4px var(--null);
  transition: none;
  margin: 3px 0;
}
.cuse--selected--solo {
  border-radius: 50px;
  margin: 3px;
}
.cuse--selected--first {
  border-radius: 50px 0 0 50px;
  border-right: none;
  margin: 3px 0 3px 3px;
}
.cuse--selected--between {
  border-left: none;
  border-right: none;
}
.cuse--selected--last {
  border-radius: 0 50px 50px 0;
  border-left: none;
  margin: 3px 3px 3px 0;
}
.cuse--selected .null-slot {
  display: none;
}

@media (pointer: coarse) and (max-width: 900px) {
  .cuse .null-slot {
    display: block;
  }

  .cuse .slot-content {
    display: block;
  }

  .cuse--selected .slot-content {
    display: none;
  }
  .cuse--selected .null-slot {
    display: none;
  }
}
