.MuiDropzoneArea-root.user-picture-dropzone-area {
  background-color: #fafafa;
  border-radius: 100vh;
  border: none;
  max-height: 360px;
  max-width: 360px;
  min-height: auto;
  aspect-ratio: 1;
  padding: 24px;
  background-image: var(--event-dropzone-bg-url);
  background-size: cover;
  background-position: center;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-content: center;
  margin: 0 auto;
}

.MuiDropzoneArea-textContainer {
  display: flex;
  flex-direction: column;
  font-family: "Source Sans Pro";
  font-style: normal;
  height: min-content;
  width: 100%;
  margin: auto;
}

.MuiDropzoneArea-textContainer p {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #141c1f;
}

.MuiDropzoneArea-textContainer p::after {
  content: var(--MuiDropzoneArea-textContainer-after);
  display: block;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #7d8182;
  padding: 4px 0 0 0;
}

.MuiDropzoneArea-textContainer svg {
  display: none;
}
