.MuiDropzoneArea-root {
  background-color: #fafafa;
  border-radius: 8px;
  border: none;
  min-height: auto;
  padding: 24px;
  background-image: var(--event-dropzone-bg-url);
  background-size: cover;
  background-position: -50%;
  object-fit: cover;
}

.MuiDropzoneArea-textContainer {
  display: flex;
  flex-direction: column;
  font-family: "Source Sans Pro";
  font-style: normal;
}

.MuiDropzoneArea-textContainer p {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #141c1f;
}

.MuiDropzoneArea-textContainer p::after {
  content: var(--MuiDropzoneArea-textContainer-after);
  display: block;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #7d8182;
  padding: 4px 0 0 0;
}

.MuiDropzoneArea-textContainer svg {
  display: none;
}


