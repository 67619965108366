html,
body,
button,
input,
select,
option {
  font-family: "Source Sans Pro", sans-serif;
}

html {
  box-sizing: border-box;
  /* font-size: 16px; */
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol,
ul,
li {
  list-style: none;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: auto;
}

table {
  border-collapse: collapse;
}

@media (hover: none) {
  html {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
  }
}
