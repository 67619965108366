:root {
  --toggle-active-location-in: 120ms;
  --toggle-active-location-dt: 200ms;
}

.App {
  text-align: center;
}

.footer {
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
}

.footer-button {
  margin: 10px;
  cursor: pointer;
  flex: 1;
}

.MuiTableCell-root {
  border-bottom: 0;
}

.date-picker-item > div {
  align-self: end;
}

.date-picker-input {
  width: 400px;
}

.date-picker-input > label {
  font-size: 0.9em;
}

.cuse,
.user-slot-entry {
  transition-property: color border-color background-color;
  transition-delay: var(--toggle-active-location-in);
  transition-duration: var(--toggle-active-location-dt);
  transition-timing-function: ease-in-out;
}

.cuse--no-transition {
  transition: none;
}
