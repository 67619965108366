.pacman {
  position: fixed;
  left: -20px;
  top: var(--pacman-y);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  visibility: var(--pacman-visibility);
  pointer-events: none;
  transform: translateX(var(--pacman-x, 0)) scaleX(var(--pacman-direction, 0));
  z-index: 100;
}

.pacman img {
  width: 40px;
  height: 40px;
}

@media (hover: hover) {
  .pacman {
    transform: translateX(var(--pacman-x, 0)) scaleX(var(--pacman-direction, 0));
  }
}
